import "./Drinks.style.css";
import logo from "../../assets/rest-logo.png";

function Drinks() {
  return (
    <div className="Drinks">
      <div className="drinks_header">
        <img className="drinks_firm_logo" src={logo} alt="rest logo" />
        <p className="drinks_firm_name">Златен Връх</p>
        <p className="drinks_firm_desc">
          Изкачете се до върха на вкусовете, където българската традиция среща
          изяществото на съвременната кухня. Живей живота!
        </p>
      </div>

      <div className="drinks_list">
        <p className="drinks_list_header">Коктейли</p>
        <div className="drinks_item">
          <p className="drinks_item_name">Мохито</p>
          <div className="drinks_item_pricing">
            <p className="drinks_item_price">18,00</p>
            <p className="drinks_item_price_lv">лв</p>
          </div>
        </div>
        <div className="drinks_item">
          <p className="drinks_item_name">Мохито</p>
          <div className="drinks_item_pricing">
            <p className="drinks_item_price">18,00</p>
            <p className="drinks_item_price_lv">лв</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drinks;
