import logo from "./assets/vednagaqr.svg";
import rest_logo from "./assets/rest-logo.png";
import map_geo from "./assets/map-pin.svg";
import drinks from "./assets/drinks.png";
import foods from "./assets/foods.png";
import desserts from "./assets/desserts.png";
import "./App.css";

import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="gradient_top"></div>

      <div className="home_firm_info">
        <img className="home_firm_logo" src={rest_logo} alt="restaurant_logo" />
        <p className="home_firm_name">Златен Връх</p>
        <p className="home_firm_desc">
          Изкачете се до върха на вкусовете, където българската традиция среща
          изяществото на съвременната кухня.
        </p>
        <div className="home_firm_geo">
          <img className="home_geo_logo" src={map_geo} alt="geo_logo" />
          <p className="home_firm_geo_loc">Varna, BG</p>
        </div>
      </div>

      <div className="home_menu_list">
        <h2 className="home_menu_header">Меню</h2>
        <hr className="home_menu_divider" />

        <div className="home_menu_container">
          <p className="home_menu_subhead">Нашите напитки</p>
          <p className="home_menu_mainhead">Страхотна Глътка</p>
          <p className="home_menu_desc">
            Най-добрият начин да се насладите на момента. Най-качествените
            коктейли, най-щастливият момент от времето.
          </p>
          <img className="home_menu_image" src={drinks} />
          <Link to="drinks">
            <button className="home_menu_button">Виж Повече</button>
          </Link>
        </div>

        <hr className="home_menu_divider" />

        <div className="home_menu_container">
          <p className="home_menu_subhead">Нашата храна</p>
          <p className="home_menu_mainhead">Незабравим Вкус</p>
          <p className="home_menu_desc">
            Вкусовете на внимателно приготвените за вас ястия няма да оставят
            небцето ви равнодушно. Опитайте тези вкусове.
          </p>
          <img className="home_menu_image" src={foods} />
          <Link to="meals">
            <button className="home_menu_button">Виж Повече</button>
          </Link>
        </div>

        <hr className="home_menu_divider" />

        <div className="home_menu_container">
          <p className="home_menu_subhead">Нашите десерти</p>
          <p className="home_menu_mainhead">Хапка От Рая</p>
          <p className="home_menu_desc">
            Нашите най-добри десерти ще ви накарат да се почувствате като в рая.
            Нашите торти, сладкиши, макарони и др. са в нашето меню.
          </p>
          <img className="home_menu_image" src={desserts} />
          <Link to="desserts">
            <button className="home_menu_button">Виж Повече</button>
          </Link>
        </div>
      </div>

      <div className="home_footer">
        <img className="home_footer_logo" src={logo} alt="logo-vednaga" />
        <p className="home_footer_desc">
          Неразрешеното използване е нарушение на правата. Ако желаете да
          работим заедно, моля, свържете се с нас по имейл.
        </p>
        <button className="home_footer_mailto">
          <a href="mailto:info@vednagaqr.com">info@vednagaqr.com</a>
        </button>
      </div>
    </div>
  );
}

export default App;
